<template>
  <figure class="highlight">
    <b-spinner label="Loading..." v-if="loading"></b-spinner>

    <b-container class="mt-6" fluid v-if="!loading">
      <h3 class="py-4">
        <span class="text-capitalize">Discount Requests Pending Approval</span>
        <small class="text-muted">&nbsp;&nbsp;by TNL</small>
      </h3>
      <b-alert
        :show="dismissCountDown"
        dismissible
        variant="success"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <p>Status updated successfully</p>
        <b-progress
          variant="success"
          :max="dismissSecs"
          :value="dismissCountDown"
          height="4px"
        ></b-progress>
      </b-alert>

      <b-alert
        :show="dismissCountDownFail"
        dismissible
        variant="danger"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChangedFail"
      >
        <p>Error updating status</p>
        <b-progress
          variant="danger"
          :max="dismissSecs"
          :value="dismissCountDownFail"
          height="4px"
        ></b-progress>
      </b-alert>

      <div class="orders">
        <p class="text-center mt-5" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </p>
        <div class="p-5" v-else>
          <b-nav-form>
            <b-form-input
              class="mr-sm-2"
              placeholder="Filter"
              v-model="filter"
            ></b-form-input>
            <b-button
              size="sm"
              class="my-2 my-sm-0"
              v-if="filter"
              @click="filter = ''"
              >Clear</b-button
            >
          </b-nav-form>
          <b-table
            show-empty
            small
            stacked="md"
            :items="pendingOrders"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            class="mt-3"
          >
            <template v-slot:cell(DeliveryDate)="row">
              {{ row.value | onlyDate }}
            </template>
            <template v-slot:cell(DiscountProofURL)="row">
              <a
                v-if="row.item.DiscountProofURL"
                :href="row.item.DiscountProofURL"
                target="_blank"
                >View</a
              >
              <!-- <router-link
                variant="warning"
                :to="row.item.DiscountProofURL"
                v-if="row.item.DiscountProofURL"
                target="_blank"
              > 
                Download
              </router-link>-->
            </template>

            <template v-slot:cell(actions)="row">
              <!-- v-if="loggedInUser.IsDealerLiason != 1" -->

              <b-button
                size="lg"
                @click="handleClick(row.item)"
                class="mr-1"
                variant="danger"
                >View Details</b-button
              >
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-pagination"
          ></b-pagination>

          <hr />
        </div>
        <!-- title="'Order Confirmation - ' + clickedCoupon.OrderNumber" -->
        <b-modal
          v-model="detailsModal"
          size="xl"
          :title="
            'Discount Approval for Order - ' +
              clickedCoupon.OrderNumber +
              ' (' +
              clickedCoupon.DealerName +
              ')'
          "
          hideFooter
          centered
        >
          <span
            class="text-danger label label-danger"
            v-if="displayErrorMessage"
          >
            Please fill at least one of the Discount Approved Amount before
            Approving
          </span>

          <table class="car-options mt-4">
            <!-- <tr>
                <th>Order Number</th>
                <td>:</td>
                <td>{{ clickedCoupon.OrderNumber }}</td>
            </tr>-->
            <tr>
              <th>Order Date</th>
              <td>:</td>
              <td>{{ clickedCoupon.OrderDate | formatDate }}</td>
            </tr>
            <tr>
              <th>Total Amount</th>
              <td>:</td>
              <td>{{ clickedCoupon.OrderTotal | amount }}</td>
            </tr>
          </table>

          <br />

          <div style="overflow: scroll; width: 100%">
            <table
              class="
                table
                b-table
                cart-table
                table-striped
                responsive
                table-bordered table-hover
              "
            >
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Colour</th>
                  <th>Unit(s)</th>
                  <th>Unit Price</th>
                  <th>Unit Discs Requested</th>
                  <th>Total Disc Requested</th>
                  <th v-if="loggedInUser.IsDealerLiason != 1">
                    Unit Disc Approved
                  </th>
                  <th v-if="loggedInUser.IsDealerLiason != 1">
                    Total Approved
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  role="row"
                  v-for="(item, i) in clickedCoupon.orderDetails"
                  :key="i"
                >
                  <td>{{ item.VehicleName }}</td>
                  <td>{{ item.Colour }}</td>
                  <td>{{ item.OrderQty }}</td>
                  <td>{{ item.UnitPrice | amount }}</td>
                  <td>{{ item.DiscountRequested | amount }}</td>
                  <td>
                    {{ (item.DiscountRequested * item.OrderQty) | amount }}
                  </td>
                  <td v-if="loggedInUser.IsDealerLiason != 1">
                    <input
                      type="number"
                      v-if="item.DiscountRequested"
                      size="sm"
                      class="form-control discount-amount"
                      v-model="item.DiscountApproved"
                      @keyup="setMaxValue(i)"
                      min="0"
                      @change="setMaxValue(i)"
                    />
                  </td>
                  <td v-if="loggedInUser.IsDealerLiason != 1">
                    {{ (item.DiscountApproved * item.OrderQty) | amount }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th
                    :colspan="loggedInUser.IsDealerLiason != 1 ? '7' : '5'"
                    style="text-align: right"
                  >
                    <h4>Order Total Before VAT:</h4>
                  </th>
                  <th>
                    <h4>{{ stepBack(clickedCoupon.OrderTotal) | amount }}</h4>
                  </th>
                </tr>

                <tr>
                  <th
                    :colspan="loggedInUser.IsDealerLiason != 1 ? '7' : '5'"
                    style="text-align: right"
                  >
                    <h5>Requested Discount Total:</h5>
                  </th>
                  <th>
                    <h5>{{ totalDiscount | amount }}</h5>
                  </th>
                </tr>

                <tr>
                  <th
                    :colspan="loggedInUser.IsDealerLiason != 1 ? '7' : '5'"
                    style="text-align: right"
                  >
                    <h5>Total Discount Approved:</h5>
                  </th>
                  <th>
                    <h5>{{ totalDiscountApproved | amount }}</h5>
                  </th>
                </tr>

                <tr>
                  <th
                    :colspan="loggedInUser.IsDealerLiason != 1 ? '7' : '5'"
                    style="text-align: right"
                  >
                    <h5>Total After Discount:</h5>
                  </th>
                  <th>
                    <h5>
                      {{
                        (stepBack(clickedCoupon.OrderTotal) -
                          totalDiscountApproved)
                          | amount
                      }}
                    </h5>
                  </th>
                </tr>

                <tr>
                  <th
                    style="text-align: right"
                    :colspan="loggedInUser.IsDealerLiason != 1 ? '7' : '5'"
                  >
                    <h5>VAT(7.5%):</h5>
                  </th>
                  <th>
                    <h5>
                      {{
                        ((7.5 / 100) *
                          (stepBack(clickedCoupon.OrderTotal) -
                            totalDiscountApproved))
                          | amount
                      }}
                    </h5>
                  </th>
                </tr>
                <!-- 
                <tr>
                  <th
                    :colspan="loggedInUser.IsDealerLiason != 1 ? '7' : '5'"
                    style="text-align: right"
                  >
                    <h3>Order Total After VAT:</h3>
                  </th>
                  <th>
                    <h3>{{ clickedCoupon.OrderTotal | amount }}</h3>
                  </th>
                </tr>
 -->
                <tr>
                  <th
                    :colspan="loggedInUser.IsDealerLiason != 1 ? '7' : '5'"
                    style="text-align: right"
                  >
                    <h3>New Total:</h3>
                  </th>
                  <th>
                    <h3>{{ newOrderTotal | amount }}</h3>
                  </th>
                </tr>
              </tfoot>
              <!---->
            </table>
          </div>
          <br />
          <div class="text-right" v-if="loggedInUser.IsDealerLiason != 1">
            <b-button class="mr-2" @click="saveInfo(1)" variant="success"
              >Approve</b-button
            >

            <b-button @click="saveInfo(0)" variant="danger">Reject</b-button>
          </div>
        </b-modal>
      </div>
    </b-container>
  </figure>
</template>

<script>
// @ is an alias to /src
//import allCars from "../data/allCars";
// eslint-disable-next-line no-unused-vars
import VehicleService from "../../service/vehicle";
import { getData } from "../../utils/storage";

export default {
  data() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
      dismissCountDownFail: 0,
      tabIndex: 0,
      maxDisountFromBackend: {},
      fields: [
        {
          key: "sNo",
          label: "S/N",
          sortable: false,
          sortDirection: "desc"
        },
        {
          key: "DealerName",
          label: "Dealer Name",
          sortable: true,
          sortDirection: "desc"
        },
        // {
        //   key: "OrderNumber",
        //   label: "Order Number",
        //   sortable: true,
        //   sortDirection: "desc",
        // },
        {
          key: "OrderDate",
          label: "Order Date",
          sortable: true,
          sortDirection: "desc",
          formatter: "formatDate"
        },
        {
          key: "OrderTotal",
          label: "Amount",
          sortable: true,
          sortDirection: "desc",
          formatter: "amount"
        },
        {
          key: "DiscountReason",
          label: "Discount Reason",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "DiscountProofURL",
          label: "Discount Proof",
          sortable: true,
          sortDirection: "desc"
        } /*,
        {
          key: "OwnersName",
          label: "Time Left",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "PaymentStatus",
          label: "Status",
          sortable: true,
          sortDirection: "desc"
        }*/,
        { key: "actions", label: "Discount Approval" }
      ],
      gettingCoupons: true,
      clickedCoupon: {},
      detailsModal: false,
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      pageOptions: [5, 10, 15],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      dealerid: 0,
      pendingOrders: [],
      pendingOrderDetails: [],
      loading: false,
      selectedorder: {},
      document: null,
      confirmPayment: false,
      displayErrorMessage: false,
      totalDiscount: 0,
      totalDiscountApproved: 0,
      newOrderTotal: 0,
      loggedInUser: null
    };
  },
  created() {
    if (this.isLogisticsOfficer) window.location = "/adlogistics";
    this.hello();
    this.loggedInUser = getData("userInfo");

    this.dealerid = this.loggedInUser.DealerID;
    this.getpendingOrders();
  },
  computed: {
    totalUnit() {
      let totalUnit = this.clickedCoupon.orderDetails
        ? this.clickedCoupon.orderDetails.reduce(
            (accumulator, currentValue) => {
              return parseInt(currentValue.OrderQty) + accumulator;
            },
            0
          )
        : 0;
      return totalUnit;
    }
  },
  methods: {
    stepBack(amount) {
      return (100 * amount) / 107.5;
    },
    getpendingOrders: async function() {
      this.loading = true;
      const { data } = await VehicleService.getAdminPendingOrders();
      this.loading = false;
      this.pendingOrders = data.Data;
      this.pendingOrderDetails = data.Data;
      this.totalRows = this.pendingOrders.length;
    },
    handleClick(obj) {
      this.clickedCoupon = obj;
      this.clickedCoupon.orderDetails = obj.Lines;
      this.selectedorder = obj;
      this.selectedorder.PaymentStatus = 0;
      this.detailsModal = true;
      this.tabIndex = 0;
      let that = this;
      let orderID = this.clickedCoupon.OrderNumber;
      this.getDicountsForVehicles(orderID);
      that.totalDiscount = obj.Lines.reduce(function(accumulator, item) {
        return accumulator + item.DiscountRequested * item.OrderQty;
      }, 0);

      that.newOrderTotal =
        parseFloat(obj.OrderTotal) + parseFloat(that.totalDiscountApproved);
    },
    getDicountsForVehicles: async function(orderID) {
      const { data } = await VehicleService.getAvailableDiscounts(orderID);
      console.log("Data", this.clickedCoupon.orderDetails);
      const mapInFunction = this.clickedCoupon.orderDetails.map(e => e);
      if (!data) {
        console.log("No data");
        return;
      } else {
        console.table(data.Data);
      }
      for (var e in mapInFunction) {
        let item = mapInFunction[e]["ItemNo"];
        let dta = data.Data.ItemDiscount.filter(x => x["Item"] === item);
        let dta2 = data.Data.OrderDiscount.filter(x => x["Item"] === item);
        if (dta[0]) {
          if (dta2[0]) {
            this.maxDisountFromBackend[item] =
              dta[0]["Discount"] > dta2[0]["Discount"]
                ? dta[0]["Discount"]
                : dta2[0]["Discount"];
          } else {
            this.maxDisountFromBackend[item] = dta[0]["Discount"];
          }
        }
        for (var i in this.clickedCoupon.orderDetails) {
          const val = this.clickedCoupon;
          const order = val.orderDetails[i];
          const orderID = order["ItemNo"];
          const itemDiscount = this.maxDisountFromBackend[orderID];
          this.clickedCoupon.orderDetails[i].DiscountApproved = itemDiscount;
        }
      }
    },
    setMaxValue(i) {
      const val = this.clickedCoupon;
      const order = val.orderDetails[i];
      const orderID = order["ItemNo"];
      const itemDiscount = this.maxDisountFromBackend[orderID];
      const discountApproved = order["DiscountApproved"];
      if (parseInt(discountApproved) && itemDiscount) {
        if (parseInt(discountApproved) > itemDiscount) {
          this.clickedCoupon.orderDetails[i]["DiscountApproved"] = itemDiscount;
        }
      }
      // else {
      //   this.clickedCoupon.orderDetails[i]["DiscountApproved"] = 0;
      // }
    },
    removeOrder(orderid) {
      let orders = this.pendingOrders;
      this.pendingOrders = orders.filter(function(obj) {
        return obj.OrderID !== orderid;
      });
    },
    saveInfo: async function(action) {
      this.displayErrorMessage = false;
      const discountApprovedCount = this.selectedorder.Lines.filter(
        x => Number(x.DiscountApproved) > 0
      );
      if (action === 1 && discountApprovedCount < 1) {
        this.displayErrorMessage = true;
        return;
      }

      this.selectedorder.ApproveFlag = action == 1;

      this.selectedorder.Lines.map(obj => {
        obj.DiscountApproved = obj.DiscountApproved || 0;
        return obj;
      });
      this.loading = true;
      try {
        const { data } = await VehicleService.updateAdminOrderStatus(
          this.selectedorder
        );
        if (data.Data) {
          this.detailsModal = false;
          this.showSuccessAlert();
          this.selectedorder = {};
          this.getpendingOrders();
        } else {
          this.showFailureAlert();
        }
        console.log("data: ", data);
      } catch (ex) {
        this.showFailureAlert();
      }
      this.loading = false;
      this.detailsModal = false;
    },
    checkPaymentForm() {
      if (!this.selectedorder) {
        return false;
      }
      if (
        !this.selectedorder.PaymentMode ||
        !this.selectedorder.AmountPaid ||
        !this.selectedorder.DealerBank ||
        !this.selectedorder.TNLBank
      ) {
        return false;
      }
      return true;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    countDownChangedFail(dismissCountDown) {
      this.dismissCountDownFail = dismissCountDown;
    },
    showSuccessAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showFailureAlert() {
      this.dismissCountDownFail = this.dismissSecs;
    }
  },
  filters: {},
  watch: {
    clickedCoupon: {
      handler: function(val) {
        let that = this;
        that.totalDiscountApproved = val.orderDetails.reduce(function(
          accumulator,
          item
        ) {
          return (
            parseFloat(accumulator) +
            parseFloat(item.DiscountApproved) * parseFloat(item.OrderQty)
          );
        },
        0);
      },
      deep: true
    },

    totalDiscountApproved: {
      handler: function(val) {
        let that = this;
        that.newOrderTotal =
          parseFloat(that.clickedCoupon.OrderTotal) - parseFloat(val);
        //console.log("newOrderTotal is ", that.newOrderTotal);
      }
    }
  }
};
</script>
<style scoped>
.wizard-navigation .stepTitle span {
  font-size: 2rem !important;
  font-weight: 500;
  line-height: 1.2;
}
.mt-6 {
  margin-top: 4rem !important;
}
</style>
